<template>
  <BRow>
    <BCol
      cols="12"
      class="d-flex justify-content-between"
    >
      <div
        class="
              bg-light
              d-flex
              justify-content-center
              align-items-center
              p-50
              rounded
            "
      >
        <span class="text-black mr-50"> List per halaman: </span>
        <BButton
          v-for="page in optionsPage"
          :key="page"
          class="btn-icon"
          size="sm"
          :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
          @click="handleSetPerpage(page)"
        >
          {{ page }}
        </BButton>
      </div>

      <BPagination
        v-if="totalRows > 0"
        v-model="localCurrentPage"
        :total-rows="totalRows"
        :per-page="totalPerPage"
        first-number
        last-number
        class="pagination-primary"
        @input="handleSetCurrentPage"
      >
        <template #prev-text>
          <feather-icon
            size="18"
            icon="ChevronLeftIcon"
          />
        </template>
        <template #next-text>
          <feather-icon
            size="18"
            icon="ChevronRightIcon"
          />
        </template>
      </BPagination>
    </BCol>
  </BRow>
</template>

<script>
import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BPagination,
  },
  props: {
    optionsPage: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    totalPerPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localCurrentPage: this.currentPage,
    }
  },
  methods: {
    handleSetPerpage(data) {
      this.$emit('handlePerpage', data)
    },
    handleSetCurrentPage(newVal) {
      this.$emit('handleCurrentPage', newVal)
    },
  },
}

</script>
