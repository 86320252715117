<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <div
            class="text-black text-2xl font-semibold
            d-flex"
            style="align-items: center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
              alt=""
              srcset=""
              class="mr-1"
              style="cursor: pointer;"
              @click="handleBack"
            >
            Detail Event
          </div>
        </b-col>
      </b-row>
      <BOverlay
        variant="light"
        :show="state.loading"
        spinner-variant="primary"
        blur="0"
        opacity="0.5"
        rounded="sm"
      >
        <div class="lg:p-2">
          <b-row
            class="mt-7 mb-1"
            align-h="start"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Poster
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div
                v-b-modal.modal-view-image
                class="cursor-pointer ml-2 d-grid justify-content-center"
                @mouseover="mouseOver"
                @mouseleave="mouseLeave"
              > <div
                  v-if="state.hover"
                  class="cursor-pointer d-grid justify-content-center items-center  bg-black"
                  style=" margin-bottom: -73px; position: relative;width: 120px; height: 74px; opacity: 0.7; align-items: center; display: grid; justify-items: center;"
                  @click="viewImage(state.dataEvent)"
                >
                  <div class="text-center">
                    <b-img
                      fluid
                      center
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/eye.svg"
                      style="width: 16px;"
                    />
                    <p
                      class="text-white text-center text-sm"
                    >
                      Lihat Gambar
                    </p>
                  </div>
                </div>
                <b-img
                  :src="state.dataEvent.poster"
                  fluid
                  alt="poster-image"
                  style="width: 120px; height: 74px; object-fit: cover;"
                />
              </div>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222] flex items-center">
                Judul Event
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ state.dataEvent.event_title }}
              </div>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Sub-Judul Event
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ state.dataEvent.event_sub_title }}
              </div>
            </b-col>
          </b-row>
          <b-row
            class="mt-7 mb-1"
            align-h="start"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Type Event
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ state.dataEvent.event_type }}
              </div>
            </b-col>
          </b-row>
          <b-row
            class="mt-7 mb-1"
            align-h="start"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Narasumber
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              :
              <div class="ml-2">
                <span
                  v-for="(options, index) in state.dataNarasumber"
                  :key="index"
                >
                  {{ options.name }} ({{ options.position }})
                  <span v-if="index < state.dataNarasumber.length - 1">, </span>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Jumlah Peserta
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ state.dataEvent.total_attendance }} / {{ state.dataEvent.max_attendance }}
              </div>
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-h="start"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Harga
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ formatRp(state.dataEvent.event_price) }}
              </div>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Diselenggarakan
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ state.dataEvent.event_status }}
              </div>
            </b-col>
          </b-row>
          <b-row
            class="mb-1"
            align-h="start"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Waktu
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ DAY_MONTH_YEAR(state.dataEvent.event_date_start) }}
              </div>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Lokasi
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ state.dataEvent.event_location }}
              </div>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Link Landing Page
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              :  <a
                class="ml-2"
                style="color: blue;"
                @click="goToEvent(state.dataEvent.link_landingpage)"
              >{{ state.dataEvent.link_landingpage }}</a>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Link Form
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              :  <a
                v-if="state.dataEvent.link_form"
                class="ml-2"
                style="color: blue;"
                @click="goToEventForm(state.dataEvent.link_form)"
              >{{ state.urlKomclass }}{{ state.dataEvent.link_form }}</a>
            </b-col>
          </b-row>
          <b-row
            align-h="start"
            class="mb-1"
          >
            <b-col
              cols="4"
              lg="2"
            >
              <div class="text-[#222222]">
                Deskripsi
              </div>
            </b-col>
            <b-col
              class="text-[#222222] d-flex"
            >
              : <div class="ml-2">
                {{ state.dataEvent.description }}
              </div>
            </b-col>
          </b-row>
        </div>
      </BOverlay>
      <modalViewImageVue :data-modal="state.dataModal" />
    </b-card>

    <b-card>
      <template #header>
        <span class="text-black text-2xl font-semibold">
          Daftar Peserta
        </span>
      </template>
      <b-row class="items-center mb-1 justify-between">
        <b-col cols="auto">
          <div class="d-flex items-center gap-[10px] mb-1">
            <div>
              <BInputGroup class="wrapper-search">
                <template #prepend>
                  <BInputGroupText>
                    <b-img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                    />
                  </BInputGroupText>
                </template>
                <BFormInput
                  v-model="state.search"
                  placeholder="Cari Nama Peserta"
                  type="search"
                  @input="debounceSearch"
                />
              </BInputGroup>
            </div>
            <div>
              <b-dropdown
                id="dropdown-1"
                variant="primary"
                toggle-class="toggle-custom"
                no-caret
                size="sm"
                :disabled="state.loading"
              >
                <template #button-content>
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                    alt=""
                  >
                </template>
                <b-dropdown-item disabled>
                  <div class="text-black font-semibold text-xl">
                    Filter
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-dropdown
                    id="dropdown-2"
                    variant="none"
                    toggle-class="toggle-form"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <div class="text-[16px]">
                        Payment
                      </div>
                    </template>
                    <b-dropdown-item
                      v-for="(item, index) in state.listFilter"
                      :key="index"
                      :active="activeItem === item.text"
                      @click="handleFilter(item)"
                    >
                      <div class="d-flex items-center">
                        <span>
                          {{ item.text }}
                        </span>
                        <img
                          v-if="activeItem === item.text"
                          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/Checklist.svg"
                          alt="icon"
                        >
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-dropdown-item>
                <b-dropdown-item @click="handleResetFilter">
                  <div class="font-semibold text-danger text-[16px]">
                    Reset Filter
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-col>
        <b-col
          cols="auto"
          class="d-flex justify-end gap-[10px] mb-1"
        >
          <b-button
            variant="primary"
            :disabled="(state.dataEvent.total_attendance === state.dataEvent.max_attendance) ? true : false"
            class="btn-sm"
            @click="addParticipant"
          >
            Tambah Peserta
          </b-button>
          <b-button
            variant="outline-primary"
            class="d-flex items-center gap-[10px] btn-sm"
            :disabled="state.loading"
            @click="getDownloadContact"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/export-3.svg"
              alt="export data"
            >
            <!-- <Excel
              :data="state.excelData"
              :fields="downloadFields"
              :name="documentName()"
            >
              Export Data
            </Excel> -->
            Export Data
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BOverlay
            id="infinite-list"
            variant="light"
            :show="state.loading"
            spinner-variant="primary"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="state.items"
              :fields="state.fields"
              hover
              show-empty
              empty-text="Belum ada peserta yang terdaftar"
              responsive
              class="mt-1"
              @row-clicked="handleViewParticipants"
            >
              <template #cell(no)="data">
                <div>
                  {{ data.index + 1 }}
                </div>
              </template>
              <template #cell(name)="data">
                <div class="text-lg">
                  {{ data.item.name }}
                </div>
                <div>
                  <p
                    class="text-[#828282]"
                    style="font-size: small;"
                  >
                    {{ data.item.email }}
                  </p>
                </div>
              </template>
              <template #cell(handphone)="data">
                <div class="d-flex items-center gap-4">
                  <div
                    style="cursor: pointer;"
                    class=""
                    @click="openWa(data.item.phone)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
                      alt=""
                      srcset=""
                    >
                  </div>
                  <div>
                    0{{ data.item.phone }}
                  </div>
                </div>
              </template>
              <template #cell(city)="data">
                <div>
                  {{ data.item.city }}
                </div>
              </template>
              <template #cell(product)="data">
                <div>
                  <div v-if="!data.item.product.komship && !data.item.product.komtim && !data.item.product.kompack & !data.item.product.komcard && !data.item.product.boostr && !data.item.product.komclass && !data.item.product.pendamping_umkm">
                    <p
                      class="text-[#828282]"
                      style="font-size: small;"
                    >
                      Belum ada produk yang terdaftar
                    </p>
                  </div>
                  <div
                    v-else
                    class="d-flex container-img"
                  >
                    <div
                      v-if="data.item.product.komship"
                      class="w-[3rem] h-[3rem] opacit"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/logo/Product-Komship.svg"
                        alt="logo-komship"
                        class="mb-[-6px]"
                      >
                    </div>
                    <div
                      v-if="data.item.product.komtim"
                      class="w-[3rem] h-[3rem] opacit"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/logo/logo_komtim.svg"
                        alt="logo-komtim"
                      >
                    </div>
                    <div
                      v-if="data.item.product.kompack"
                      class="w-[3rem] h-[3rem] opacit"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                        alt="logo-kompack"
                      >
                    </div>
                    <div
                      v-if="data.item.product.komcard"
                      class="w-[3rem] h-[3rem] opacit"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/logo/Product-Komcards.svg"
                        alt="logo-komcard"
                      >
                    </div>
                    <div
                      v-if="data.item.product.boostr"
                      class="w-[3rem] h-[3rem] opacit"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komplace.svg"
                        alt="logo-boostr"
                        class="h-6"
                      >
                    </div>
                    <div class="w-[3rem] h-[3rem] opacit">
                      <img
                        v-if="data.item.product.pendamping_umkm"
                        src="https://storage.googleapis.com/komerce/assets/logo/Product-Pendamping%20UMKM.svg"
                        alt="logo-p-umkm"
                        class="h-6"
                      >
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(payment)="data">
                <div>
                  <b-dropdown
                    :id="`dropdown-${data.item.id}`"
                    v-model="data.item.Payment"
                    :variant="getButtonVariant(data.item.Payment)"
                    class="w-100"
                    size="sm"
                  >
                    <template #button-content>
                      {{ getPaymentText(data.item.Payment) }}
                    </template>
                    <b-dropdown-item
                      v-for="(item, index) in state.listFilter"
                      :key="index"
                      @click="updatePaymentStatus(data.item.id, item.value)"
                    >
                      {{ item.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
              <template #cell(action)="data">
                <div
                  class="d-flex"
                  style="gap: 1rem; min-width: 20px !important "
                >
                  <div
                    style="cursor: pointer;"
                    @click.stop="$bvModal.show('modal-delete-participant',handleDeleteParticipant(data.item.id))"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                      alt="logo-eye"
                    >
                  </div>
                  <div
                    style="cursor: pointer;"
                    @click.stop="handleEdit(data.item)"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/edit-yellow.svg"
                      alt="logo-edit"
                      srcset=""
                    >
                  </div>
                </div>
              </template>
            </BTable>
          </BOverlay>
        </b-col>
      </b-row>
      <modal-delete-participant
        v-model="state.modalShow"
        @submit="handlesubmitDeleteParticipant"
      />
    </b-card>

  </div>
</template>

<script>
import { reactive, onMounted, ref } from '@vue/composition-api'
import router from '@/router'
import { useRouter } from '@/@core/utils/utils'
import { newAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { columnsTableParticipant, toastError, toastSuccess } from '../config'
import modalDeleteParticipant from './modalDeleteParticipant.vue'
import modalViewImageVue from './ModalViewImage.vue'

export default {
  components: {
    modalDeleteParticipant,
    modalViewImageVue,
  },
  data() {
    return {
      isDropdown2Visible: false,
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  setup() {
    const route = useRouter()
    const filterSelected = ref([])
    const downloadFields = ref({
      Nama: 'name',
      Email: 'email',
      Phone: 'phone',
      Whatsapp: 'whatsapp',
      City: 'city',
      Payment: 'Payment',
      Komship: 'product.komship',
      Kompack: 'product.kompack',
      Komtim: 'product.komtim',
      Komcard: 'product.komcard',
      Komplace: 'product.boostr',
      PUMKM: 'product.pendamping_umkm',
    })
    const state = reactive({
      dataModal: {},
      hover: false,
      id: route.route.value.query.id,
      url: '',
      idParticipant: '',
      loading: false,
      dataEvent: '',
      fields: columnsTableParticipant,
      items: [],
      modalShow: false,
      dataNarasumber: '',
      urlKomclass: process.env.VUE_APP_BASE_URL_EVENT_KOMCLASS,
      search: '',
      listFilter: [
        { text: 'Belum Bayar', value: 'Belum_Bayar' },
        { text: 'Gratis', value: 'Gratis' },
        { text: 'Sudah Bayar', value: 'Sudah_Bayar' },
      ],
      debounceSearch: null,
      excelData: [],
    })

    async function getDataEvent() {
      state.loading = true
      const url = `/komclass/api/v1/event/${state.id}?attendance_name=${state.search}&payment_status=${filterSelected.value}`
      const { data } = await newAxiosIns.get(url)
      state.items = data.data.attendance
      state.excelData = data.data.attendance
      state.dataEvent = data.data.event_detail
      state.dataNarasumber = data.data.performers
      state.loading = false
    }

    function goToEvent(event) {
      let link = event
      if (!link.startsWith('https://')) {
        link = `https://${link}`
      }
      window.open(link, '_blank')
    }
    function goToEventForm(event) {
      const link = `${process.env.VUE_APP_BASE_URL_EVENT_KOMCLASS}/form?event=${event}`
      window.open(link, '_blank')
    }

    function addParticipant() {
      router.push({
        name: 'data-event-participant-add',
        query: { idEvent: state.id },
      })
    }

    function formatRp(number) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number)
    }

    function handleDeleteParticipant(id) {
      const idEvent = state.id
      const idParticipant = id
      state.url = `/komclass/api/v1/event/${idEvent}/attendance/${id}/delete`
    }

    async function handlesubmitDeleteParticipant() {
      const urlApi = state.url
      await newAxiosIns.delete(urlApi)
        .then(res => {
          const { data } = res.data
          // eslint-disable-next-line no-console
          toastSuccess('Berhasil menghapus peserta')
          getDataEvent()
        })
        .catch(err => {
          toastError('Ada kesalahan saat menghapus peserta')
        })
    }

    function handleBack() {
      router.back()
    }
    function openWa(phoneNumber) {
      window.open(`https://wa.me/62${phoneNumber}`)
    }

    function handleEdit(data) {
      router.push({
        name: 'data-event-participant-edit',
        query: { idEvent: state.id },
        params: { participant: data },
      })
    }

    const mouseOver = () => {
      state.hover = true
    }

    const mouseLeave = () => {
      state.hover = false
    }

    const viewImage = data => {
      state.dataModal = data
    }

    function handleViewParticipants(item) {
      const idEvent = item.id
      const { name } = item
      const { email } = item
      const { phone } = item
      const { city } = item
      const { product } = item
      router.push({
        name: 'data-event-detail-participants',
        query: {
          id: idEvent,
          name,
          email,
          phone,
          city,
          product,
        },
      })
    }

    async function updatePaymentStatus(id, value) {
      state.loading = true
      const url = `/komclass/api/v1/attendances/${id}/payment`
      const { data } = await newAxiosIns.put(url, { status: value })
      if (data.meta.status === 'success') {
        getDataEvent()
        state.loading = false
        toastSuccess('Berhasil merubah status pembayaran')
      } else {
        state.loading = false
        toastError('Gagal merubah status pembayaran')
      }
    }

    const getPaymentText = paymentStatus => {
      if (paymentStatus === 'Belum_Bayar') {
        return 'Belum Bayar'
      } if (paymentStatus === 'Sudah_Bayar') {
        return 'Sudah Bayar'
      } if (paymentStatus === 'Gratis') {
        return 'Gratis'
      }
      return 'Belum Bayar'
    }

    const activeItem = ref(null)
    function handleResetFilter() {
      filterSelected.value = []
      activeItem.value = null
      getDataEvent()
    }
    function handleFilter(item) {
      filterSelected.value = item.value
      activeItem.value = item.text
      getDataEvent()
    }

    async function handleSearch(value) {
      if (!value.length) {
        state.search = ''
        getDataEvent()
      } else if (value.length > 2) {
        state.loading = true
        state.search = value
        getDataEvent()
      } else {
        state.loading = false
      }
    }

    function debounceSearch(search) {
      if (!search) {
        state.resultSearch = false
      }
      if (state.debounceSearch) {
        clearTimeout(state.debounceSearch)
      }
      state.debounceSearch = setTimeout(() => {
        handleSearch(search)
      }, 1000)
    }

    const getButtonVariant = paymentStatus => {
      if (paymentStatus === 'Sudah_Bayar' || paymentStatus === 'Gratis') {
        return 'success'
      }
      return 'light'
    }

    function documentName() {
      return `${state.dataEvent.event_title + state.dataEvent.event_sub_title}`
    }

    async function getDownloadContact() {
      state.loading = true
      await newAxiosIns.get(`/komclass/api/v1/question-answer/event/${state.id}`)
        .then(res => {
          const { data } = res.data
          const a = document.createElement('a')
          a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.file}`
          a.download = `${data.filename}`
          a.click()
          state.loading = false
          toastSuccess('Berhasil Export Data')
        })
        .catch(err => {
          state.loading = false
          toastError('Gagal Export Data')
        })
    }

    onMounted(() => {
      getDataEvent()
    })

    return {
      DAY_MONTH_YEAR,
      handleDeleteParticipant,
      route,
      mouseOver,
      state,
      openWa,
      formatRp,
      handlesubmitDeleteParticipant,
      addParticipant,
      handleBack,
      handleEdit,
      mouseLeave,
      viewImage,
      goToEvent,
      goToEventForm,
      handleViewParticipants,
      updatePaymentStatus,
      filterSelected,
      handleResetFilter,
      handleFilter,
      debounceSearch,
      handleSearch,
      getPaymentText,
      getButtonVariant,
      documentName,
      downloadFields,
      getDownloadContact,
      activeItem,
    }
  },
}
</script>

<style scooped>
.container-img {
  gap: 1px;
}

.opacit {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 2px;
}
.frame {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 74px;
  margin: auto;
}
.frame p {
 position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
#dropdown-2 button {
  padding: 0px !important;
}
#dropdown-2 button:hover {
  background-color: transparent;
}
</style>
