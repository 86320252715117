<template>
  <BCard>
    <BRow class="items-center justify-between">
      <strong class="text-black text-bold text-2xl ml-1">Risk Review</strong>
    </BRow>
    <BRow class="my-1">
      <BCol lg="3">
        <BCard body-class="bg-[#E2E2E2] rounded-[8px]">
          <div class="text-[#222222]">
            On Review
          </div>
          <BSpinner
            v-if="loadingCard"
            small
            variant="primary"
          />
          <span
            v-else
            class="text-black text-xl"
          >{{ dataWidget.on_review }}</span>
        </BCard>
      </BCol>
      <BCol lg="3">
        <BCard body-class="bg-[#FCEBBE] rounded-[8px]">
          <div class="flex items-center">
            <div class="text-[#222222]">
              Hold
            </div>
            <img
              v-b-tooltip.hover.top="'Akun tidak bisa login lagi & otomatis terlog-out di semua device yg pernah login'"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
            >
          </div>
          <BSpinner
            v-if="loadingCard"
            small
            variant="primary"
          />
          <span
            v-else
            class="text-black text-xl"
          >{{ dataWidget.hold }}</span>
        </BCard>
      </BCol>
      <BCol lg="3">
        <BCard body-class="bg-[#BEFCDE] rounded-[8px]">
          <div class="flex items-center">
            <div class="text-[#222222]">
              Safe
            </div>
            <img
              v-b-tooltip.hover.top="'Akun pernah direview dan hold namun sekarang sudah dibuka dan dapat login kembali'"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
            >
          </div>
          <BSpinner
            v-if="loadingCard"
            small
            variant="primary"
          />
          <span
            v-else
            class="text-black text-xl"
          >{{ dataWidget.unhold }}</span>
        </BCard>
      </BCol>
      <BCol lg="3">
        <BCard body-class="bg-[#FCBEBE] rounded-[8px]">
          <div class="flex items-center">
            <div class="text-[#222222]">
              Fake
            </div>
            <img
              v-b-tooltip.hover.top="'Akun tidak bisa login, data order berubah menjadi fake dan mengeluarkan data tersebut dari ekosistem agar tidak tercampur dengan data valid'"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px] cursor-pointer"
            >
          </div>
          <BSpinner
            v-if="loadingCard"
            small
            variant="primary"
          />
          <span
            v-else
            class="text-black text-xl"
          >{{ dataWidget.fake }}</span>
        </BCard>
      </BCol>
    </BRow>
    <BRow class="mx-1">
      <BCol md="4">
        <BButton
          class="mr-[5px] mb-1"
          :variant="activeBtn ? 'primary' : 'light'"
          @click="filterLastUpdate"
        >
          Last Update
        </BButton>
        <BButton
          class="mb-1"
          :variant="!activeBtn ? 'primary' : 'light'"
          @click="filterTopProblem"
        >
          Top Problem
        </BButton>
      </BCol>
      <BCol md="8">
        <BRow class="justify-end">
          <BCol md="6">
            <BFormInput
              v-model="search"
              placeholder="Ketik email user atau nama user"
              style="width: 45%"
              class="mr-1 w-100 mb-1"
              @input="searchData"
            />
          </BCol>
          <BCol md="5">
            <v-select
              v-model="statusFilter"
              placeholder="Semua"
              style="width: 35%"
              :options="statusOptions"
              :reduce="opt => opt.value"
              class="w-100 mb-1"
            />
          </BCol>
        </BRow>
      </BCol>
    </BRow>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-2"
        hover
      >
        <template #cell(full_name)="data">
          <div class="flex min-w-[150px]">
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              alt="Komerce"
              @click="handlePhone(data.item.phone_number)"
            >
            <div class="ml-[5px]">
              <div class="cursor-pointer d-flex">
                <img
                  v-if="data.item.badge_url !== null"
                  class="w-6 mr-[5px]"
                  :src="data.item.badge_url"
                  alt="badge"
                >
                <span>{{ data.item.full_name }}</span>
              </div>
              <div class="text-[#828282] text-[12px]">
                {{ data.item.email }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(join_date)="data">
          <div class="font-semibold min-w-[140px]">
            {{ DAY_MONTH_YEAR(data.item.join_date) }}
          </div>
        </template>
        <template #cell(last_update)="data">
          <div class="min-w-[140px]">
            <div class="font-semibold">
              {{ DAY_MONTH_YEAR(data.item.last_update) }}
            </div>
            <div class="text-black">
              {{ data.item.running_orders }} Order
            </div>
          </div>
        </template>
        <template #head(expedition_ratio)="data">
          <span
            v-b-tooltip.hover.top="`Data dihitung dari 3x pickup terakhir`"
            class="font-semibold"
          >
            {{ data.label }}
          </span>
        </template>
        <template #cell(expedition_ratio)="data">
          <div class="flex items-center min-w-[110px]">
            <span class="font-semibold">
              {{ data.item.expedition_ratio.percentage }}%
            </span>
            <img
              :src="data.item.expedition_ratio.shipping_image_url"
              alt="Komerce"
              style="width: 50px"
            >
          </div>
        </template>
        <template #head(payment_method_ratio)="data">
          <span
            v-b-tooltip.hover.top="`Data dihitung dari 3x pickup terakhir`"
            class="font-semibold"
          >
            {{ data.label }}
          </span>
        </template>
        <template #cell(payment_method_ratio)="data">
          <div class="font-semibold flex min-w-[110px]">
            <span class="mr-1">{{ data.item.payment_method_ratio.percentage }}%</span>
            <span>{{ data.item.payment_method_ratio.payment_name }}</span>
          </div>
        </template>
        <template #cell(main_warehouse)="data">
          <div class="font-semibold flex items-center min-w-[110px]">
            <span
              v-if="data.item.main_warehouse.city_name === ''"
              class="font-semibold"
            >
              -
            </span>
            <span
              v-else
              class="w-[8rem] font-semibold"
            >
              {{ data.item.main_warehouse.city_name }}
            </span>
            <img
              v-if="data.item.main_warehouse.address_detail !== ''"
              v-b-tooltip.hover.top="`${data.item.main_warehouse.address_detail}`"
              variant="outline-primary"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              class="ml-[5px]"
            >
          </div>
        </template>
        <template #head(product_ratio)="data">
          <span
            v-b-tooltip.hover.top="`Data dihitung dari 3x pickup terakhir`"
            class="font-semibold"
          >
            {{ data.label }}
          </span>
        </template>
        <template #cell(product_ratio)="data">
          <div class="flex items-center min-w-[120px]">
            <span class="font-semibold">{{ data.item.product_ratio.product_percentage }}%</span>
            <img
              v-if="data.item.product_ratio.image_path === null"
              v-b-tooltip.hover.top="`${data.item.product_ratio.product_variant_percentage}% No Varian`"
              src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
              alt="Komerce"
              style="width: 40px; height: 40px;"
              class="ml-1"
            >
            <img
              v-else
              v-b-tooltip.hover.top="`${data.item.product_ratio.product_variant_percentage}% No Varian`"
              :src="data.item.product_ratio.image_path"
              alt="Komerce"
              style="width: 40px; height: 40px;"
              class="ml-1"
            >
          </div>
        </template>
        <template #cell(problem_ratio)="data">
          <div class="flex items-center min-w-[100px]">
            <div class="w-24 text-center">
              <span class="font-semibold">{{ data.item.problem_ratio.percentage }}%</span>
              <div class="text-[12px]">
                {{ `(${data.item.problem_ratio.top_problem_comparison[0]}/${data.item.problem_ratio.top_problem_comparison[1]})` }}
              </div>
            </div>
            <img
              v-b-tooltip.hover.top="`${data.item.problem_ratio.top_problem_description} ${data.item.problem_ratio.top_problem_percentage}% (${data.item.problem_ratio.top_problem_comparison[0]}/${data.item.problem_ratio.top_problem_comparison[1]})`"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
              variant="outline-primary"
              class="ml-[5px]"
            >
          </div>
        </template>
        <template #cell(retur_percentage)="data">
          <div class="text-center">
            <div class="font-semibold min-w-[100px]">
              {{ data.item.retur_percentage }} %
            </div>
            <div class="text-[12px]">
              {{ data.item.recent_balance | rupiah }}
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="justify-content-between d-flex">
            <BDropdown
              v-if="data.item.user_status !== 1"
              variant="outline-primary"
            >
              <template v-slot:button-content>
                <span :class="'font-bold ' + handleDropdownText('class',data.item.user_status)">
                  {{ handleDropdownText('text', data.item.user_status) }}
                </span>
              </template>
              <BDropdownItem
                v-if="data.item.user_status !== 2"
                v-b-modal.modalFakeUser
                @click="setDataFake({partnerId: data.item.partner_id, status: 2})"
              >
                <div class="text-[#FBA63C] font-bold">
                  <span class="mr-1 text-2xl">&bull;</span> Hold
                </div>
              </BDropdownItem>
              <BDropdownItem
                v-if="data.item.user_status !== 3"
                v-b-modal.modalFakeUser
                @click="setDataFake({partnerId: data.item.partner_id, status: 3})"
              >
                <div class="text-[#34A770] font-bold">
                  <span class="mr-1 text-2xl">&bull;</span> Safe
                </div>
              </BDropdownItem>
              <BDropdownItem
                v-b-modal.modalFakeUser
                @click="setDataFake({partnerId: data.item.partner_id, status: 1})"
              >
                <div class="text-[#E31A1A] font-bold">
                  <span class="mr-1 text-2xl">&bull;</span> Fake
                </div>
              </BDropdownItem>
            </BDropdown>
            <div
              v-if="data.item.user_status === 1"
              class="flex items-center justify-center fakeUser"
            >
              <span>Fake</span>
            </div>
            <span
              v-if="data.item.is_manual === 1"
              type="button"
              class="k-note-1 text-2xl ml-1"
              @click="historyHold(data.item.partner_id)"
            />
          </div>
        </template>
      </BTable>
      <BSpinner
        v-if="isLoading"
        small
        variant="light"
      />
    </BOverlay>
    <ModalFakeUser
      :partner-id="partnerId"
      :status="status"
      :get-data="getData"
      :get-data-card="getDataCard"
    />
    <ModalHistoryHold
      :data-hold="dataHold"
    />
  </BCard>
</template>

<script>
import { BCard, BRow, BFormInput } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import filters from '@/libs/filters'
import { tableConfig, statusOptions } from './config'
import ModalFakeUser from './ModalFakeUser.vue'
import ModalHistoryHold from './ModalHistoryHold.vue'

export default {
  components: {
    BCard,
    BRow,
    BFormInput,
    ModalFakeUser,
    ModalHistoryHold,
    vSelect,
  },
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      statusFilter: 0,
      status: 0,
      statusOptions,
      items: [],
      fields: tableConfig,
      isLoading: false,
      DAY_MONTH_YEAR,
      partnerId: 0,
      search: '',
      limit: 20,
      offset: 0,
      lastData: false,
      loadingCard: false,
      dataWidget: {},
      activeBtn: true,
      filtersBtn: 'last_update',
      dataHold: {},
    }
  },
  watch: {
    statusFilter() {
      this.getData()
    },
  },
  mounted() {
    this.getData()
    this.getDataCard()
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('scroll').offsetHeight && !this.isLoading) {
        this.getNextData()
      }
    }
  },
  methods: {
    async getData() {
      this.isLoading = true
      this.offset = 0
      const url = '/v1/admin/monitor/fake-user'
      const params = {
        limits: this.limit,
        offset: this.offset,
        name_email: this.search,
        filter: this.statusFilter,
        order_by: this.filtersBtn,
      }
      await komshipAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.items = data
          this.isLoading = false
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    getNextData() {
      if (!this.lastData) {
        this.isLoading = true
        const url = '/v1/admin/monitor/fake-user'
        const params = {
          limits: this.limit,
          offset: this.offset,
          name_email: this.search,
          filter: this.statusFilter,
          order_by: this.filtersBtn,
        }
        komshipAxiosIns.get(url, { params })
          .then(result => {
            const { data } = result.data
            this.items.push(...data)
            this.offset += data.length
            this.isLoading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            })
          })
      }
    },
    async getDataCard() {
      this.loadingCard = true
      const url = '/v1/admin/monitor/fake-user-card'
      await komshipAxiosIns.get(url)
        .then(res => {
          this.loadingCard = false
          const { data } = res.data
          this.dataWidget = data
        })
    },
    searchData: _.debounce(function search() {
      this.getData()
    }, 1000),
    handlePhone(value) {
      window.open(`https://wa.me/62${value.substring(1)}`, '_blank')
    },
    setDataFake(data) {
      const { partnerId, status } = data
      this.partnerId = partnerId
      this.status = status
    },
    handleDropdownText(part, status) {
      if (part === 'class') {
        if (status === 2) return 'text-warning'
        if (status === 3) return 'text-success'
      }
      if (status === 2) return 'Hold'
      if (status === 3) return 'Unhold'
      return 'On Review'
    },
    filterLastUpdate() {
      this.activeBtn = true
      this.filtersBtn = 'last_update'
      this.getData()
    },
    filterTopProblem() {
      this.activeBtn = false
      this.filtersBtn = 'top_problems'
      this.getData()
    },
    async historyHold(partnerId) {
      const url = `komship/api/v1/admin/fake-users/${partnerId}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.dataHold = data
          this.$nextTick(() => {
            this.$bvModal.show('historyHold')
          })
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal mengambil data, silahkan coba lagi!' })
        })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.cursor-pointer:hover {
  color: #34a770;
  font-weight: 600;
}
.fakeUser {
  color: #E31A1A;
  border-radius: 10px;
  padding: 0 15px;
  margin: auto;
  background-color: #FCBEBE;
  font-weight: 600;
}
</style>
