export const columnConfig = [
  {
    key: 'ticket_no',
    label: 'No. Tiket',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'cnote',
    label: 'No. Resi',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'created_at',
    label: 'Tanggal',
    class: 'p-1 align-middle',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'user_fullname',
    label: 'Nama User',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'shipping',
    label: 'Ekspedisi',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'claim_type',
    label: 'Jenis Claim',
    class: 'p-1 align-middle text-left',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'nominal',
    label: 'Nilai Nominal',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'claim_proof',
    label: 'Bukti',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'order_id',
    label: 'Data Lain',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'status_claim',
    label: 'Action',
    class: 'p-1 align-middle text-center',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
]

export const statusType = [
  {
    label: 'Semua',
    onCheck: false,
  },
  {
    label: 'Nunggu Review',
    value: 0,
    onCheck: false,
  },
  {
    label: 'Disetujui',
    value: 1,
    onCheck: false,
  },
  {
    label: 'Ditolak',
    value: 2,
    onCheck: false,
  },
]
