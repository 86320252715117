import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import pages from './routes/pages'
import dashboard from './routes/dashboard'
import membership from './routes/komship'
import ekspedisi from './routes/ekspedisi/index'
import training from './routes/training/index'
import talent from './routes/talent/index'
import partner from './routes/partner/index'
import masterData from './routes/master-data/index'
import management from './routes/management/index'
import division from './routes/division'
import talentPool from './routes/talent-pool'
import parentProfile from './routes/partner-profile'
import wishlist from './routes/wishlist'
import invoice from './routes/invoice'
import assignment from './routes/assignment'
import jobRoleSetting from './routes/job-role-setting'
import notification from './routes/notification'
import tiket from './routes/tiket/index'
import returArea from './routes/analytic'
import claimRetur from './routes/claim-retur'
import monitoringPickup from './routes/komship/monitoring-pickup'
import kompack from './routes/kompack'
import fullfilment from './routes/fullfilment'
import riskReview from './routes/risk-review'
import metricsFinancial from './routes/metrics-financial'
import affiliate from './routes/affiliate'
import metricsRealiability from './routes/metrics-realiability'
import withdrawal from './routes/withdrawal'
import position from './routes/position'
import searchOrder from './routes/search-order'
import talentKomtim from './routes/komtim/talent'
import trainingKomtim from './routes/komtim/training'
import managementKomtim from './routes/komtim/management'
import masterDataKomtim from './routes/komtim/master-data'
import partnerKomtim from './routes/komtim/partner'
import invoiceKomtim from './routes/komtim/invoices'
import assignmentKomtim from './routes/komtim/assignment'
import komclass from './routes/komclass'
import slaMonitoring from './routes/komship/sla-monitoring'
import pendampingUmkm from './routes/pumkm'
import monitoringOrder from './routes/komship/monitoring-order'
import verificationPartnerKomcard from './routes/komcards'
import churnPartner from './routes/komtim/churn-partner'
import homepage from './routes/homepage'
import managementAdmin from './routes/management-admin'
import komchat from './routes/komchat'
import openApi from './routes/open-api'

// komform
import komform from './routes/komform/dashboard'
import rekonsiliasi from './routes/rekonsiliasi'

// Komads
import komads from './routes/komads/komads'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...homepage,
    ...dashboard,
    ...membership,
    ...ekspedisi,
    ...pages,
    ...training,
    ...talent,
    ...partner,
    ...masterData,
    ...management,
    ...division,
    ...invoice,
    ...assignment,
    ...notification,
    ...talentPool,
    ...parentProfile,
    ...wishlist,
    ...jobRoleSetting,
    ...tiket,
    ...returArea,
    ...claimRetur,
    ...monitoringPickup,
    ...kompack,
    ...fullfilment,
    ...riskReview,
    ...metricsFinancial,
    ...affiliate,
    ...pendampingUmkm,
    ...metricsRealiability,
    ...position,
    ...searchOrder,
    ...slaMonitoring,
    ...komclass,

    ...assignmentKomtim,
    ...talentKomtim,
    ...trainingKomtim,
    ...managementKomtim,
    ...masterDataKomtim,
    ...partnerKomtim,
    ...invoiceKomtim,
    ...withdrawal,
    ...slaMonitoring,
    ...monitoringOrder,
    ...verificationPartnerKomcard,
    ...churnPartner,
    ...managementAdmin,
    ...rekonsiliasi,
    ...komform,
    ...komads,
    ...komchat,
    ...openApi,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.name === 'unauthenticated' || to.name === 'error-404') {
    return next()
  }

  if (!canNavigate(to) && !isLoggedIn) {
    // Redirect to login if not logged in
    return next({ name: 'auth-login' })
  }

  // Redirect if logged in
  if (
    // eslint-disable-next-line operator-linebreak
    (to.meta.redirectIfLoggedIn && isLoggedIn) ||
    (!canNavigate(to) && isLoggedIn && !to.meta.preventRedirect)
  ) {
    const userData = getUserData()
    next(
      getHomeRouteForLoggedInUser(
        // eslint-disable-next-line comma-dangle
        userData ? userData.role_name.toUpperCase() : null,
        // eslint-disable-next-line comma-dangle
      ),
    )
  }

  return next()
})

export default router
