<template>
  <BModal
    id="modal-reject-request"
    ref="modal-reject-request"
    hide-footer
    size="md"
    hide-header
    centered
    hide-header-close
  >
    <div
      class="p-[32px]"
    >
      <div>
        <div class="text-[#333333] text-center font-bold text-[20px]">
          Want to reject the document submission?
        </div>
        <div
          class="text-[#626262] text-center leading-[24px] text-[16px] mt-1"
        >
          Please provide detailed information about the reason for the rejection so that the collaborator can resubmit acceptable documents.
        </div>
        <div class="mt-1">
          <b-form-textarea
            id="textarea"
            v-model="notes"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            @input="inputNotes()"
          />
          <div class="d-flex justify-content-between">
            <small
              v-if="notes.length === 0"
              class="text-danger d-flex justify-content-start"
            >
              Notes must be filled in
            </small>
            <small
              v-else
              class="d-flex justify-content-start"
            />
            <span class="d-flex justify-content-end">{{ notes.length }}/250</span>
          </div>
        </div>
      </div>
      <div class="flex gap-[10px] mt-[32px]">
        <BButton
          variant="primary"
          class="w-full"
          @click="$bvModal.hide('modal-reject-request')"
        >
          Cancel
        </BButton>
        <BButton
          variant="outline-secondary"
          class="w-full"
          :disabled="notes.length === 0"
          @click="rejectReq()"
        >
          Reject
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { collaboratorAxiosIns } from '@/libs/axios'

export default {
  props: {
    getSLA: {
      type: Function,
      default: () => {},
    },
    getCA: {
      type: Function,
      default: () => {},
    },
    getUAT: {
      type: Function,
      default: () => {},
    },
    getDetail: {
      type: Function,
      required: true,
    },
    documentType: {
      type: String,
      default: '',
    },
    paramsUAT: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      notes: '',
      userId: null,
    }
  },
  mounted() {
    const { id } = this.$router.history.current.params
    this.userId = id
  },
  methods: {
    inputNotes() {
      if (this.notes.length > 250) {
        this.notes = this.notes.substring(0, 250)
      }
    },
    async rejectReq() {
      const params = {
        document_type: this.documentType,
        state: 6,
        notes: this.notes,
      }
      const url = '/user/api/v1/admin/user-document-state'
      await collaboratorAxiosIns.put(`${url}/${this.userId}`, params)
        .then(res => {
          this.$toast_success({ message: 'Request Rejected' })
          this.getDetail()
          if (this.document_type === 'SLA') this.getSLA()
          if (this.document_type === 'CA') this.getCA()
          if (this.document_type === 'UAT') this.getUAT()

          this.$bvModal.hide('modal-reject-request')
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal Mengambil Data' })
        })
    },
  },
}
</script>
