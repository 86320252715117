<template>
  <div
    class="border rounded-lg py-[16px] px-0"
  >
    <div class="flex px-[16px] items-center">
      <span class="text-base font-bold">{{ product.name }}</span>
      <button
        v-if="open"
        :disabled="(stateLeads === initLeads && stateCr === initCr) || loading"
        class="text-white font-semibold bg-[#F95031] disabled:bg-slate-200 ms-auto mr-[10px] rounded px-[5px] py-[2px] flex items-center"
        @click="updateTarget()"
      >
        Simpan
      </button>
      <span
        v-if="(!product.leads && !product.closing_rate) && !open"
        class="ms-[5px] k-info-circle text-primary text-lg font-bold"
      />
      <button
        :class="!open && 'ms-auto'"
        class="bg-[#F8F8F8] rounded px-[5px] py-[2px] flex items-center"
        @click="open = !open"
      >
        <span
          v-if="!open"
          class="k-arrow-down-1"
        />
        <span
          v-else
          class="k-arrow-up-2"
        />
      </button>
    </div>
    <b-collapse
      v-model="open"
      class="pt-[16px] border-t mt-[16px]"
      @show="getPerformance()"
    >
      <div class="flex flex-col px-[16px] gap-[16px] pb-[16px] border-b">
        <b-col
          cols="12"
          class="p-0 d-flex items-center gap-[10px]"
        >
          <b-col
            cols="3"
            class="p-0"
          >
            Target leads
          </b-col>
          <b-col
            cols="8"
            class="p-0 flex items-center border rounded w-100"
          >
            <b-form-input
              v-model.number="stateLeads"
              placeholder="Masukkan target leads"
              type="number"
              class="!border-0 !rounded-none"
            />
          </b-col>
          <span
            class="text-primary k-edit ms-auto"
          />
        </b-col>
        <b-col
          cols="12"
          class="p-0 d-flex items-center gap-[10px]"
        >
          <b-col
            cols="3"
            class="p-0"
          >
            Target CR
          </b-col>
          <b-col class="p-0 flex items-center border rounded pr-1 w-100">
            <b-form-input
              v-model.number="stateCr"
              placeholder="Masukkan target CR"
              type="number"
              class="!border-r !border-y-0 !border-l-0 !rounded-none"
            />
            <div class="pl-1 h-full">
              %
            </div>
          </b-col>
          <span
            class="text-primary k-edit ms-auto"
          />
        </b-col>
      </div>
      <div class="flex p-[16px]">
        <span class="font-semibold text-lg">Performance</span>
        <div class="month-picker w-[130px] border flex justify-between rounded items-center px-[5px] py-[5px] ms-auto">
          <MonthlyPicker
            v-model="filterMonth"
            :month-labels="monthlabel"
            date-format="MMM YYYY"
            style="width: 90px;"
            input-class="border-0 w-[100px] h-full font-semibold"
            :clear-option="false"
            :alignment="'center'"
            :selected-background-color="'#F95031'"
            @selected="getPerformance()"
          />
          <span class="k-calendar text-3xl" />
        </div>

      </div>
      <div class="px-[16px] h-[300px] overflow-y-auto">
        <div class="bg-[#f8f8f8] rounded-lg p-[16px]">
          <b-row
            v-for="(performance, index) in reversedProducts"
            :key="`${product.product_id}-${index}`"
          >
            <b-col
              cols="2"
              class="flex flex-col items-center"
            >
              <div class="week rounded-full flex items-center justify-center text-lg">
                {{ product.performance.length - index }}
              </div>
              <div
                v-if="index !== product.performance.length - 1"
                class="border-dashed border-r-2 border-[#FBA63C] d-inline-block h-100 w-[1px]"
              />
            </b-col>
            <b-col
              cols="9"
              class="flex flex-col gap-[16px] pb-[24px]"
            >
              <div class="text-[#626262]">
                {{ performance.date }}
              </div>
              <div class="flex flex-col">
                <div
                  v-if="initLeads && initCr"
                  class="flex p-[16px] rounded-top border gap-[10px] bg-white"
                >
                  <span class="k-percentage-square text-[#F95031] text-[20px]" />
                  <div class="flex flex-col">
                    {{ performance.cr }} - {{ initCr }}
                    <span class="font-[500]">Average CR is <span :class="performance.cr >= initCr ? 'text-[#34A770]' :'text-[#E31A1A]'">{{ performance.cr >= initCr ? '' : 'not ' }}on target</span></span>
                    <span class="text-sm text-[#626262]">Increased from Last Week</span>
                  </div>
                </div>
                <div
                  v-if="initLeads && initCr"
                  class="flex p-[16px] rounded-bottom border gap-[10px] bg-white"
                >
                  <span class="k-trend-up text-[#F95031] text-[20px]" />
                  <div class="flex flex-col">
                    {{ performance.leads }} - {{ initLeads }}
                    <span class="font-[500]">Average Total Leads <span :class="performance.leads >= initLeads ? 'text-[#34A770]' :'text-[#FBA63C]'">
                      <span v-if="performance.leads == initLeads"><span class="text-[#333333]">is</span> on target</span>
                      <span v-else>{{ performance.leads > initLeads ? 'increased' : 'decreased' }} {{ performance.leads > initLeads ? ((performance.leads - initLeads) / initLeads * 100).toFixed(0) : ((initLeads - performance.leads) / initLeads * 100).toFixed(0) }}% from target</span>
                    </span></span>
                    <span class="text-sm text-[#626262]">Increased from Last Week</span>
                  </div>
                </div>
                <div
                  v-else
                  class="flex p-[16px] rounded border gap-[10px] bg-[#FFF2E2]"
                >
                  <span class="k-info-circle text-[#AF6A13] text-[20px]" />
                  <span class="text-[#AF6A13]">Please enter the target</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import moment from 'moment'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import MonthlyPicker from 'vue-monthly-picker'

export default {
  components: {
    MonthlyPicker,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      open: false,
      filterMonth: moment(),
      monthlabel: LABELMONTH,
      alertError,
      alertSuccess,
      initLeads: null,
      initCr: null,
      stateLeads: null,
      stateCr: null,
      loading: false,
    }
  },
  computed: {
    reversedProducts() {
      return [...this.product.performance].reverse()
    },
  },
  mounted() {
    const { initLeads, initCr } = this.product
    this.initLeads = initLeads
    this.initCr = initCr
    this.stateLeads = initLeads
    this.stateCr = initCr
  },
  methods: {
    async getPerformance() {
      const url = `v1/talent_performance/performance?date=${YEAR_MONTH(this.filterMonth)}&product_id=${this.product.product_id}&user_id=${this.userId}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.dataDetail = data
          const { leads, closing_rate } = data
          if (leads) this.initLeads = leads
          // eslint-disable-next-line camelcase
          if (closing_rate) this.initCr = closing_rate

          this.$emit('productUpdate', data)
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async updateTarget() {
      this.loading = true

      const data = {
        product_id: this.product.product_id,
        user_id: this.userId,
        cr: this.stateCr,
        leads: this.stateLeads,
        date: YEAR_MONTH(this.filterMonth),
      }

      const params = new URLSearchParams(data)
      const url = `v1/talent_performance/update?${params}`

      await komtimAxiosIns
        .post(url)
        .then(res => {
          this.loading = false
          this.alertSuccess('Berhasil mengubah target')
          this.getPerformance()
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
  },
}
</script>
