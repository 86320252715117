<template>
  <div class="d-flex flex-column">
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listSLAFiles"
        :fields="fieldSLATab"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        sticky-header="600px"
        class="mt-1"
      >
        <template #head(document)="data">
          <div class="d-flex flex-column">
            <span>{{ data.label }}</span>
            <small class="text-secondary font-italic">(Upload business legal documents)</small>
          </div>
        </template>
        <template #head(notes)="data">
          <div class="d-flex flex-column">
            <span>{{ data.label }}</span>
            <small class="text-secondary font-italic">(To be filled in for any missing legal documents)</small>
          </div>
        </template>
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex"
          >
            <span>{{ data.item.list }}
              <span
                v-if="data.item.required"
                class="text-danger"
              >*</span>
              <small class="text-secondary font-italic">{{ data.item.sub }}</small>
            </span>
          </div>
        </template>
        <template #cell(document)="data">
          <div
            class="d-flex"
          >
            <a
              v-if="data.item.uploaded_file_path"
              :href="data.item.uploaded_file_path"
              target="_blank"
              class="text-info d-flex gap-5"
            ><img
              src="https://storage.googleapis.com/komerce/assets/icons/link-file-icon.svg"
              alt="file"
              width="20px"
            >{{ data.item.file_name }}</a>
          </div>
        </template>
        <template #cell(notes)="data">
          <div class="d-flex align-items-center gap-5">
            {{ data.item.notes || '-' }}
          </div>
        </template>
      </BTable>
    </BOverlay>
    <hr>
    <div class="d-flex justify-content-end gap-5">
      <b-button
        variant="light"
        size="md"
        @click="rejectRequest()"
      >
        Reject
      </b-button>
      <b-button
        variant="primary"
        size="md"
        @click="approveRequest()"
      >Approve</b-button>
    </div>
    <ModalReject
      :get-sla="getSLA"
      :get-detail="getDetail"
      :document-type="docType"
    />
    <ModalApprove
      :get-sla="getSLA"
      :get-detail="getDetail"
      :document-type="docType"
    />
  </div>
</template>

<script>
import { collaboratorAxiosIns } from '@/libs/axios'
import { fieldSLATab, listSLAFiles } from '../config'
import ModalReject from './ModalRejectRequest.vue'
import ModalApprove from './ModalApproveRequest.vue'

export default {
  components: {
    ModalReject,
    ModalApprove,
  },
  props: {
    getDetail: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fieldSLATab,
      listSLAFiles,
      userId: null,
      docType: '',
      isApprove: false,
      loading: false,
    }
  },
  mounted() {
    const { id } = this.$router.history.current.params
    this.userId = id
    this.getSLA()
  },
  methods: {
    rejectRequest() {
      this.docType = 'SLA'
      this.$bvModal.show('modal-reject-request')
    },
    approveRequest() {
      this.docType = 'SLA'
      this.$bvModal.show('modal-approve-request')
    },
    async getSLA() {
      this.loading = true
      const url = '/user/api/v1/admin/document-request/service-level-agreement'
      await collaboratorAxiosIns.get(`${url}/${this.userId}`)
        .then(res => {
          const updatedList = [...this.listSLAFiles]
          res.data.data.forEach(newItem => {
            const existingItemIndex = updatedList.findIndex(item => item.key === newItem.document_type)
            if (existingItemIndex !== -1) {
              this.$set(updatedList, existingItemIndex, { ...updatedList[existingItemIndex], ...newItem })
            } else {
              updatedList.push(newItem)
            }
          })
          this.listSLAFiles = updatedList
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal Mengambil Data' })
        })
    },
  },
}
</script>
