export default {
  namespaced: true,
  state: {
    searchHistory: '',
    currentPageHistory: null,
    perPageHistory: null,
  },
  mutations: {
    UPDATE_SEARCH_HISTORY(state, text) {
      state.searchHistory = text
    },
    UPDATE_PAGE_HISTORY(state, value) {
      state.perPageHistory = value
    },
    UPDATE_CURRENT_PAGE_HISTORY(state, value) {
      state.currentPageHistory = value
    },
  },
  actions: {
    updateSearchHistory({ commit }, text) {
      commit('UPDATE_SEARCH_HISTORY', text)
    },
    updatePageHistory({ commit }, value) {
      commit('UPDATE_PAGE_HISTORY', value)
    },
    updateCurrentPageHistory({ commit }, value) {
      commit('UPDATE_CURRENT_PAGE_HISTORY', value)
    },
  },
}
