<template>
  <div>
    <div class="w-full my-2 d-flex justify-content-end">
      <MonthlyPicker
        v-model="filter.date"
        date-format="MMMM YYYY"
        place-holder="30 Hari Terakhir"
        class="filter"
        :clear-option="false"
        :month-labels="monthlabel"
        :max="maxDatePicker"
        style="margin-top: 5px"
      />
    </div>
    <BRow class="my-2">
      <BCol
        v-for="(item, idx) in listCard.slice(0, 4)"
        :key="idx"
        lg="3"
        class="mb-2"
      >
        <BCard class="border border-[1px] border-[#E2E2E2] m-0 h-[13rem]">
          <div class="text-black flex items-center">
            <img
              :src="item.icon"
              alt=""
              class="mr-[5px] w-10 border rounded-lg p-[2px]"
            >
            <span>{{ item.title }}</span>
            <img
              v-if="item.tooltipMessage"
              v-b-tooltip.hover.top
              :title="item.tooltipMessage"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="info"
              class="ml-[5px] cursor-pointer w-5"
            >
          </div>
          <BSpinner
            v-if="loadingReturInfo"
            small
            variant="primary"
          />
          <div
            v-else
            class="my-2 ml-2 text-black text-[20px] font-medium"
          >
            {{ item.count }}
          </div>
          <div
            v-if="item.issubCount"
            class="ml-2 text-black font-medium"
          >
            <span v-if="item.title === 'Disetujui'">
              {{ IDR(item.subCount) }}
            </span>
            <div
              v-else
            >
              <div
                v-if="item.subCount !== ''"
                class="d-flex"
              >
                <img
                  :src="String(item.subCount).startsWith('-') ? 'https://storage.googleapis.com/komerce/icon/arrow-down.svg' : 'https://storage.googleapis.com/komerce/icon/arrow-up.svg'"
                  alt="up"
                  class="w-5 mr-[5px]"
                >
                <span>
                  {{ Math.abs(item.subCount) }}
                </span>
              </div>
            </div>
          </div>
        </BCard>
      </BCol>
    </BRow>
    <BRow class="my-2">
      <BCol
        v-for="(item, idx) in listCard.slice(4, 6)"
        :key="idx"
        lg="6"
        class="mb-2"
      >
        <BCard class="border border-[1px] border-[#E2E2E2] m-0">
          <div class="text-black flex items-center">
            <img
              :src="item.icon"
              alt=""
              class="mr-[5px] w-10 border rounded-lg p-[2px]"
            >
            <span>{{ item.title }}</span>
            <img
              v-if="item.tooltipMessage"
              v-b-tooltip.hover.top
              :title="item.tooltipMessage"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="info"
              class="ml-[5px] cursor-pointer w-5"
            >
          </div>
          <BSpinner
            v-if="loadingReturInfo"
            small
            variant="primary"
          />
          <div
            v-else
            class="my-2 ml-2 text-black text-[20px] font-medium"
          >
            <span v-if="item.title === 'Rata-rata Pengajuan'">
              {{ item.count }} per hari
            </span>
            <span v-else>
              {{ handleApprovalTime(item.count) }}
            </span>
          </div>
        </BCard>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import MonthlyPicker from 'vue-monthly-picker'
import { LABELMONTH, START_DATE_OF_MONTH, END_DATE_OF_MONTH } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'

export default {
  components: {
    MonthlyPicker,
  },
  props: {},
  data() {
    return {
      listCard: [
        {
          id: 1, title: 'Jumlah Pengajuan', count: 0, icon: 'https://storage.googleapis.com/komerce/icon/box.svg', issubCount: true, subCount: 0,
        },
        {
          id: 2, title: 'Disetujui', count: 0, icon: 'https://storage.googleapis.com/komerce/icon/box-tick.svg', issubCount: true, subCount: 0, tooltipMessage: 'Rata-rata disetujui satu bulan terakhir',
        },
        {
          id: 3, title: 'Ditolak', count: 0, icon: 'https://storage.googleapis.com/komerce/icon/box-remove.svg', issubCount: false,
        },
        {
          id: 4, title: 'Menunggu Review', count: 0, icon: 'https://storage.googleapis.com/komerce/icon/box-time.svg', issubCount: false,
        },
        {
          id: 5, title: 'Rata-rata Pengajuan', count: 0, icon: 'https://storage.googleapis.com/komerce/icon/note.svg', issubCount: false, tooltipMessage: 'Rata-rata pengajuan satu bulan terakhir',
        },
        {
          id: 6, title: 'Rata-rata Waktu Handling', count: 0, icon: 'https://storage.googleapis.com/komerce/icon/clock.svg', issubCount: false, tooltipMessage: 'Rata-rata kecepatan dibulan ini',
        },
      ],
      loadingReturInfo: true,
      monthlabel: LABELMONTH,
      filter: {
        date: this.$moment(),
      },
      IDR,
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    'filter.date': {
      handler() {
        this.getReturInfo()
      },
    },
  },
  mounted() {
    this.getReturInfo()
  },
  methods: {
    async getReturInfo() {
      this.loadingReturInfo = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.date),
        end_date: END_DATE_OF_MONTH(this.filter.date),
      }
      const url = '/komship/api/v1/admin/claims/info'
      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.listCard.map((item, index) => {
            if (item.id === 1) {
              this.listCard[index].count = data.total_request
              this.listCard[index].subCount = data.total_diff_last_day_request
            }
            if (item.id === 2) {
              this.listCard[index].count = data.total_approved
              this.listCard[index].subCount = data.total_approved_claim_nominal
            }
            if (item.id === 3) {
              this.listCard[index].count = data.total_rejected
            }
            if (item.id === 4) {
              this.listCard[index].count = data.total_on_review
            }
            if (item.id === 5) {
              this.listCard[index].count = data.average_request
            }
            if (item.id === 6) {
              this.listCard[index].count = data.average_handling_time
            }
            return item
          })
          this.loadingReturInfo = false
        })
        .catch(err => {
          this.$toast_error({ message: err.response.data.message })
          this.loadingReturInfo = false
        })
    },
    handleApprovalTime(time) {
      if (!time) return '0 Menit'

      const { day = 0, hour = 0, minute = 0 } = time
      if (day === 0 && hour === 0) {
        return `${minute} Menit`
      }

      return `${day} Hari ${hour} Jam ${minute} Menit`
    },
  },
}
</script>
