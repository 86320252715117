export default [
  {
    path: '/openapi/dashboard',
    name: 'dashboard-open-api',
    component: () => import('@/views/pages/open-api/dashboard/ListCollaborator.vue'),
    meta: {
      resource: 'Open Api',
      navActiveLink: 'open-api',
      action: 'manage',
    },
  },
  {
    path: '/openapi/collaborator/detail/:id',
    name: 'dashboard-open-api',
    component: () => import('@/views/pages/open-api/dashboard/DetailCollaborator.vue'),
    meta: {
      resource: 'Open Api',
      navActiveLink: 'open-api',
      action: 'manage',
    },
  },
  {
    path: '/openapi/document-request',
    name: 'doc-request-collaborator',
    component: () => import('@/views/pages/open-api/document-request/ListRequest.vue'),
    meta: {
      resource: 'Open Api',
      navActiveLink: 'open-api',
      action: 'manage',
    },
  },
  {
    path: '/openapi/document-request/detail/:id',
    name: 'doc-request-collaborator',
    component: () => import('@/views/pages/open-api/document-request/DetailRequest.vue'),
    meta: {
      resource: 'Open Api',
      navActiveLink: 'open-api',
      action: 'manage',
    },
  },
]
