export const fieldsCollaboratorTable = [
  {
    key: 'name',
    label: 'Collaborator Name',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'package',
    label: 'Package',
    thClass: 'font-bold capitalize text-black',
    tdClass: 'px-0 d-flex justify-content-center',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '150px',
    },
  },
  {
    key: 'phone_number',
    label: 'Phone Number',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'address',
    label: 'Address',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'api_access',
    label: 'API Access',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'detail',
    label: '',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
]

export const fieldExpeditionService = [
  {
    key: 'shipping_name',
    label: 'Expedition',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'regular',
    label: 'Regular',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'cargo',
    label: 'Cargo',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'lite',
    label: 'Lite',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'flat',
    label: 'Flat',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
]

export const fieldsRequestDoc = [
  {
    key: 'date',
    label: 'Date',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'name',
    label: 'Collaborator Name',
    thClass: 'font-bold capitalize text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'contact',
    label: 'Contact',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'sla',
    label: 'SLA',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'ca',
    label: 'CA',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'uat',
    label: 'UAT',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'detail',
    label: 'Action',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
]
export const fieldSLATab = [
  {
    key: 'name',
    label: '',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'document',
    label: 'Document',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'notes',
    label: 'Notes',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
]
export const fieldUAT = [
  {
    key: 'name',
    label: 'Name',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '200px',
    },
  },
  {
    key: 'input',
    label: 'Input',
    thClass: 'font-bold text-black',
    tdClass: 'font-italic',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'expect_result',
    label: 'Expect Result',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'curl',
    label: 'cURL',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
  {
    key: 'result',
    label: 'Result',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '200px',
    },
  },
  {
    key: 'note',
    label: 'Note',
    thClass: 'font-bold text-black',
    class: 'align-top text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      width: '250px',
    },
  },
]

export const listSLAFiles = [
  {
    key: 'sla_file',
    list: 'Service Agreement signed by the client',
    sub: '',
    required: true,
  },
  {
    key: 'amandement_business_file',
    list: 'Deed of Establishment and the most recent Amendment of the Business Entity',
    sub: '',
    required: true,
  },
  {
    key: 'npwp_file',
    list: 'Business Taxpayer Identification Number (NPWP) / Personal NPWP of the Company Owner',
    sub: '',
    required: false,
  },
  {
    key: 'nib_file',
    list: 'Business Identification Number (NIB) / Business License',
    sub: '(for Business Entity Agreements)',
    required: false,
  },
  {
    key: 'bank_account_number_file',
    list: 'Business Entity Bank Account Number / Copy of Bank Account Statement',
    sub: '',
    required: false,
  },
  {
    key: 'id_card_file',
    list: 'ID card (KTP) of the Director / Representative in charge of signing',
    sub: '',
    required: true,
  },
  {
    key: 'power_of_attorney_file',
    list: 'Power of Attorney',
    sub: '(for Directors unable to sign)',
    required: false,
  },
  {
    key: 'statement_letter_file',
    list: 'Statement Letter for Non-Business Entity Bank Accounts',
    sub: '',
    required: false,
  },
  {
    key: 'pic_dashboard',
    list: 'ID card (KTP) of the PIC for the Dashboard / API account',
    sub: '',
    required: false,
  },
]
