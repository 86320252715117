export default [
  {
    path: '/data-event',
    name: 'data-event',
    component: () => import('@/views/pages/komclass/data-event/index.vue'),
    meta: {
      resource: 'Komclass',
      navActiveLink: 'data-event',
      action: 'read',
    },
  },
  {
    path: '/data-event/add',
    name: 'data-event-add',
    component: () => import('@/views/pages/komclass/data-event/add-event/addEvent.vue'),
    meta: {
      resource: 'Komclass',
      navActiveLink: 'data-event',
      action: 'read',
    },
  },
  {
    path: '/data-event/detail',
    name: 'data-event-detail',
    component: () => import('@/views/pages/komclass/data-event/detail-event/index.vue'),
    meta: {
      resource: 'Komclass',
      navActiveLink: 'data-event',
      action: 'read',
    },
  },
  {
    path: '/data-event/detail-participants',
    name: 'data-event-detail-participants',
    component: () => import('@/views/pages/komclass/data-event/detail-event/detail-participant.vue'),
    meta: {
      resource: 'Komclass',
      navActiveLink: 'data-event',
      action: 'read',
    },
  },
  {
    path: '/data-event/edit',
    name: 'data-event-edit',
    component: () => import('@/views/pages/komclass/data-event/edit-event/index.vue'),
    meta: {
      resource: 'Komclass',
      navActiveLink: 'data-event',
      action: 'read',
    },
  },
  {
    path: '/data-event/participant/add',
    name: 'data-event-participant-add',
    component: () => import('@/views/pages/komclass/data-event/detail-event/add-participant/index.vue'),
    meta: {
      resource: 'Komclass',
      navActiveLink: 'data-event',
      action: 'read',
    },
  },
  {
    path: '/data-event/participant/edit',
    name: 'data-event-participant-edit',
    component: () => import('@/views/pages/komclass/data-event/detail-event/edit-participant/index.vue'),
    meta: {
      resource: 'Komclass',
      navActiveLink: 'data-event',
      action: 'read',
    },
  },
]
