import Vue from 'vue'
import moment from 'moment'

const axiosKomship = partnerId => {
  const axios = Vue.prototype.$http_komship
  axios.defaults.params = { partner_id: partnerId }
  return axios
}

const axiosKomshipNew = partnerId => {
  const axios = Vue.prototype.$http_new
  axios.defaults.params = { partner_id: partnerId }
  return axios
}
const axiosKompack = () => Vue.prototype.$http

const komformService = () => Vue.prototype.$http_komform

const komshipDate = new Date('2021-01-01')
const affiliateDate = new Date('2021-12-28')

const today = new Date()
today.setHours(0, 0, 0, 0)

const tomorrow = new Date()
tomorrow.setDate(today.getDate() + 1)
tomorrow.setHours(0, 0, 0, 0)

const last7 = new Date()
last7.setDate(today.getDate() - 6)
last7.setHours(0, 0, 0, 0)

const last2 = new Date()
last2.setDate(today.getDate() - 1)
last2.setHours(0, 0, 0, 0)

const last3 = new Date()
last3.setDate(today.getDate() - 2)
last3.setHours(0, 0, 0, 0)

const last120 = new Date()
last120.setDate(today.getDate() - 119)
last120.setHours(0, 0, 0, 0)

const last150 = new Date()
last150.setDate(today.getDate() - 150)
last150.setHours(0, 0, 0, 0)

const last300 = new Date()
last300.setDate(today.getDate() - 300)
last300.setHours(0, 0, 0, 0)

const startDayInYear = new Date()
startDayInYear.setMonth(0, 1)

const last30 = new Date()
last30.setDate(today.getDate() - 29)
last30.setHours(0, 0, 0, 0)

const last60 = new Date()
last60.setDate(today.getDate() - 59)
last60.setHours(0, 0, 0, 0)

const last90 = new Date()
last90.setDate(today.getDate() - 89)
last90.setHours(0, 0, 0, 0)

const firstDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
const firstDateOfMonthJuny = new Date('2023-06-01')
const lastDateOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
const lastDateOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
const lastMonth = (minusMonth = 1) => new Date(today.getFullYear(), today.getMonth() - minusMonth, 1)

const firstDateOfMonthBefore = new Date(today.getFullYear(), today.getMonth() - 1, 1)
const lastDateOfMonthBefore = new Date(today.getFullYear(), today.getMonth(), 0)

const ranges = {
  'Real Time': [today, today],
  '7 Hari Terakhir': [last7, today],
  '30 Hari Terakhir': [last30, today],
  '1 Bulan Terakhir': [last30, today],
  '2 Bulan Terakhir': [last60, today],
  '3 Bulan Terakhir': [last90, today],
  'Bulan Ini': [firstDateOfMonth, today],
  'Custom Tanggal': [null, null],
}

const penghasilanValue = {
  Kompship: 1,
  'Non Kompship': 0,
}

const paymentMethods = {
  COD: 'COD',
  'Transfer Bank': 'BANK TRANSFER',
}

const formatYmd = date => moment(date).format('YYYY-MM-DD')

const hideAccount = account => {
  if (!account) return ''
  const { length } = account
  return Array(length - 4).join('*') + account.slice(-4)
}

const getDates = (start, end) => {
  const arr = []
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(moment(new Date(dt)).format('YYYY-MM-DD'))
  }
  return arr
}

const d = new Date(firstDateOfMonth)
d.setMonth(d.getMonth() - 3)
const start3monthsAgo = d

const rupiah = number => new Intl.NumberFormat('id-ID').format(number)

export {
  axiosKomship,
  axiosKompack,
  today,
  last7,
  last30,
  last60,
  firstDateOfMonth,
  lastDateOfMonth,
  ranges,
  penghasilanValue,
  formatYmd,
  hideAccount,
  paymentMethods,
  getDates,
  last120,
  last150,
  last300,
  startDayInYear,
  last90,
  komshipDate,
  last2,
  affiliateDate,
  axiosKomshipNew,
  rupiah,
  start3monthsAgo,
  tomorrow,
  firstDateOfMonthJuny,
  lastDateOfMonthBefore,
  firstDateOfMonthBefore,
  lastDateOfLastMonth,
  lastMonth,
  komformService,
  last3,
  // startThisWeek,
  // endThisWeek,
  // startLastMonth,
  // endLastMonth,
}
