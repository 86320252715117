<template>
  <b-card>
    <b-row class="border-b-[1px] mb-2 pb-2">
      <b-col>
        <div
          class="text-black text-2xl font-semibold
          d-flex"
          style="align-items: center"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
            alt=""
            srcset=""
            class="mr-1"
            style="cursor: pointer;"
            @click="handleBack"
          >
          Detail Peserta
        </div>
      </b-col>
    </b-row>
    <BOverlay
      variant="light"
      :show="state.loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div :class="businnesItems.length > 0 ? 'border-b-[1px] mb-2 pb-2' : 'mb-2'">
        <div class="text-black text-xl mb-2">
          Data Diri
        </div>
        <b-row>
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              Nama
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ state.nameParticipant }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              Email
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ state.emailParticipant }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              No Telepon
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ '0' + state.phoneParticipant }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              Kota
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ state.cityParticipant }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              Product
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ formattedProduct }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) in personalItems"
          :key="index"
        >
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              {{ item.label }}
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ item.answer }}</span>
            </p>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="businnesItems.length > 0"
        class="border-b-[1px] mb-2 pb-2"
      >
        <div class="text-black text-xl mb-2">
          Data Bisnis
        </div>
        <b-row
          v-for="(item, index) in businnesItems"
          :key="index"
        >
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              {{ item.label }}
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ item.answer }}</span>
            </p>
          </b-col>
        </b-row>
      </div>
      <div v-if="otherItems.length > 0">
        <div class="text-black text-xl mb-2">
          Lain Lain
        </div>
        <b-row
          v-for="(item, index) in otherItems"
          :key="index"
        >
          <b-col
            md="3"
            cols="4"
          >
            <p class="text-[#828282]">
              {{ item.label }}
            </p>
          </b-col>
          <b-col
            md="9"
            cols="8"
          >
            <p class="flex gap-[10px]">
              <span>:</span>
              <span class="text-[#828282]">{{ item.answer }}</span>
            </p>
          </b-col>
        </b-row>
      </div>
    </BOverlay>
  </b-card>
</template>

<script>
import { reactive, onMounted, computed } from '@vue/composition-api'
import router from '@/router'
import { useRouter } from '@/@core/utils/utils'
import { newAxiosIns } from '@/libs/axios'
import { toastError, toastSuccess } from '../config'

export default {
  components: {},
  setup() {
    const route = useRouter()
    const state = reactive({
      id: route.route.value.query.id,
      nameParticipant: route.route.value.query.name,
      emailParticipant: route.route.value.query.email,
      phoneParticipant: route.route.value.query.phone,
      cityParticipant: route.route.value.query.city,
      productParticipant: route.route.value.query.product,
      idParticipant: '',
      loading: false,
      dataEvent: '',
      items: [],
    })

    async function getDataEvent() {
      state.loading = true
      const url = `/komclass/api/v1/attendances/${state.id}/answers`
      newAxiosIns
        .get(url)
        .then(res => {
          state.loading = false
          const { data } = res.data
          state.items = data
        })
        .catch(err => {
          state.loading = false
          toastError('Data peserta tidak ditemukan.')
        })
    }

    function handleBack() {
      router.back()
    }

    const personalItems = computed(() => state.items.filter(item => item.section === 'personal'))
    const businnesItems = computed(() => state.items.filter(item => item.section === 'business'))
    const formatDate = dateString => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const date = new Date(dateString)
      return date.toLocaleDateString('id-ID', options)
    }
    const isDate = value => /^\d{4}-\d{2}-\d{2}$/.test(value)
    const otherItems = computed(() => state.items
      .filter(item => item.section === 'other')
      .map(item => ({
        ...item,
        answer: isDate(item.answer) ? formatDate(item.answer) : item.answer,
      })))

    const formattedProduct = computed(() => {
      const productKeys = Object.keys(state.productParticipant).filter(
        key => state.productParticipant[key] === true,
      )

      if (productKeys.length === 0) {
        return '-'
      }

      return productKeys.join(', ')
    })

    onMounted(() => {
      getDataEvent()
    })

    return {
      route,
      state,
      handleBack,
      personalItems,
      businnesItems,
      otherItems,
      formattedProduct,
    }
  },
}
</script>
