<template>
  <BCard body-class="p-3">
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BRow class="items-center justify-between mb-2">
        <div class="flex items-center">
          <BButton
            variant="primary"
            size="sm"
            class="mr-1"
            @click="$router.go(-1)"
          >
            <feather-icon
              size="1x"
              icon="ChevronLeftIcon"
            />
          </BButton>
          <div class="text-black font-bold m-0 text-2xl">
            Rincian Penarikan Saldo
          </div>
        </div>
        <span :class="statusWithdrawalClass(dataWithdrawal.status)">{{ statusWithdrawal(dataWithdrawal.status) }}</span>
      </BRow>
      <BRow>
        <BCol lg="6">
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              Waktu Request
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ DAY_MONTH_YEAR(dataWithdrawal.withdrawal_date) }} ({{ TIME(dataWithdrawal.withdrawal_date) }} WIB)</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              Nama
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ dataWithdrawal.bank_account_name }}</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              No HP
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ dataWithdrawal.mitra_phone_number }}</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              Nama Bank
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ dataWithdrawal.bank_name }}</span>
            </BCol>
          </BRow>
          <BRow>
            <BCol
              lg="4"
              class="text-black"
            >
              No Rekening
            </BCol>
            <BCol
              lg="6"
              class="flex items-center"
            >
              <span class="text-black font-semibold mr-1">{{ dataWithdrawal.bank_account_no }}</span>
              <img
                v-b-tooltip.hover="`Copy nomor rekening ${dataWithdrawal.bank_account_no}`"
                src="https://storage.googleapis.com/komerce/assets/icons/copy.png"
                alt="Komerce"
                class="cursor-pointer"
                @click="toastCopyResi(dataWithdrawal.bank_account_no)"
              >
            </BCol>
          </BRow>
        </BCol>
      </BRow>
      <BRow class="mt-1">
        <BCol
          class="mb-1"
          :lg="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review' ? 3 : 4"
        >
          <BCard class="balance">
            <div class="mb-1 text-black">
              Saldo Terkini
            </div>
            <BSpinner
              v-if="isLoading"
              variant="primary"
              small
            />
            <span
              v-else
              class="font-semibold text-2xl text-[#F95031]"
            >
              {{ IDR(dataWithdrawal.current_balance) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          class="mb-1"
          :lg="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review' ? 3 : 4"
        >
          <BCard class="balance">
            <div class="mb-1 text-black">
              Request Penarikan
            </div>
            <BSpinner
              v-if="isLoading"
              variant="primary"
              small
            />
            <span
              v-else
              class="font-semibold text-2xl text-[#34A770]"
            >
              {{ IDR(dataWithdrawal.withdrawal_nominal) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          class="mb-1"
          :lg="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review' ? 3 : 4"
        >
          <BCard class="balance">
            <div class="mb-1 text-black">
              Sisa Jika Disetujui
            </div>
            <BSpinner
              v-if="isLoading"
              variant="primary"
              small
            />
            <span
              v-else
              class="font-semibold text-2xl text-[#08A0F7]"
            >
              {{ IDR(dataWithdrawal.balance_after_approval) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          v-if="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review'"
          lg="3"
          class="flex flex-column justify-center"
        >
          <div class="flex">
            <BButton
              id="popoverStatus"
              variant="outline-primary"
            >
              {{ filters.label }}
            </BButton>
            <BPopover
              id="popover-filter-type"
              target="popoverStatus"
              triggers="focus"
              placement="bottom"
            >
              <div
                v-for="(data, index) in chooseAction"
                :key="index+1"
              >
                <BButton
                  v-model="data.value"
                  variant="flat-dark"
                  @click="setActions(data)"
                >
                  {{ data.label }}
                </BButton>
              </div>
            </BPopover>
            <div class="ml-1">
              <BButton
                variant="primary"
                @click="handleActions"
              >
                Terapkan
              </BButton>
            </div>
          </div>
        </BCol>
      </BRow>
    </BOverlay>
    <div
      v-if="dataWithdrawal.notes !== ''"
      class="mt-1"
    >
      <div class="flex">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/notes.svg"
          alt="Komerce"
        >
        <p class="text-black font-bold text-start m-0">
          Catatan :
        </p>
      </div>
      <div class="mt-1">
        {{ dataWithdrawal.notes }}
      </div>
    </div>
    <BOverlay
      :show="isLoadingTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        class="mt-2"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #cell(created_at)="value">
          {{ DAY_MONTH_YEAR(value.item.created_at) }}
          <br>
          {{ TIME(value.item.created_at) }}
        </template>
        <template #cell(nominal)="value">
          <span :class="classNominal(value.item.type)">{{ labelNominal(value.item) }}</span>
        </template>
        <template #cell(type)="value">
          <span>{{ labelTransactionType(value.item.description) }}</span>
        </template>
        <template #cell(action)="value">
          <span v-if="value.item.description === 'payment_low_moving_stock'">-</span>
          <span
            v-else
            class="font-semibold cursor-pointer text-[#08A0F7]"
            @click="onRowSelected(value.item)"
          >
            Lihat Detail
          </span>
        </template>
      </BTable>
      <BRow class="justify-between mt-2">
        <h3 class="text-black font-semibold text-3xl ml-1">
          Saldo Awal :
        </h3>
        <h3
          style="color: #F95031"
          class="text-black font-semibold text-3xl mr-5"
        >
          {{ IDR(dataWithdrawal.beginning_balance) }}
        </h3>
      </BRow>
      <BRow v-if="size(items) !== 0">
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="bg-light d-flex justify-content-center align-items-center p-50 rounded">
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>

          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>

    <ModalTransferNow :get-detail-withdrawal="getDetailWithdrawal" />
    <ModalReview :get-detail-withdrawal="getDetailWithdrawal" />
    <ModalReject :get-detail-withdrawal="getDetailWithdrawal" />
  </BCard>
</template>

<script>
import { axiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { listDetailWithdrawal, chooseAction } from './config'
import ModalTransferNow from './ModalTransferNow.vue'
import ModalReview from './ModalReview.vue'
import ModalReject from './ModalReject.vue'

export default {
  components: {
    ModalTransferNow, ModalReview, ModalReject,
  },
  data() {
    return {
      isLoadingTable: false,
      isLoading: false,
      dataWithdrawal: {},
      filters: {
        label: 'Pilih Tindakan',
        value: '',
      },
      chooseAction,
      items: [],
      fields: listDetailWithdrawal,
      idWithdrawal: this.$route.params.id,
      DAY_MONTH_YEAR,
      TIME,
      IDR,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [20, 50, 100, 200, 400],
      totalPerPage: 20,
      isEmpty,
      size,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getDetailWithdrawal()
      },
    },
  },
  mounted() {
    this.getDetailWithdrawal()
  },
  methods: {
    async getDetailWithdrawal() {
      this.isLoading = true
      const url = `/v1/admin/mitra-withdrawal/detail/${this.idWithdrawal}`
      await axiosIns.get(url)
        .then(async res => {
          const { data } = res.data
          this.dataWithdrawal = data
          this.isLoading = false
          this.isLoadingTable = true

          const params = {
            total_per_page: this.totalPerPage,
            page: this.currentPage,
            withdrawal_id: this.idWithdrawal,
          }
          const urlList = `/v1/admin/mitra-withdrawal/detail/list/${data.mitra_user_id}`
          await axiosIns.get(urlList, { params })
            .then(response => {
              // eslint-disable-next-line no-shadow
              const { data, total } = response.data
              this.items = data
              this.isLoadingTable = false
              this.totalRows = total
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Failure',
                  icon: 'AlertCircleIcon',
                  text: err.response.data.message,
                  variant: 'danger',
                },
              }, 1000)
              this.isLoadingTable = false
            })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 1000)
          this.isLoading = false
        })
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getDetailWithdrawal()
    },
    setActions(data) {
      this.filters = data
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
    handleActions() {
      if (this.filters.value === 'transfer_now') return this.$bvModal.show('modal-transfer-now')
      if (this.filters.value === 'review') return this.$bvModal.show('modal-review')
      if (this.filters.value === 'tolak') return this.$bvModal.show('modal-reject')
      if (this.filters.value === 'transfer_manual') {
        return this.$router.push({ path: `/withdrawal-mitra/${this.idWithdrawal}/transfer` })
      }

      return ''
    },
    statusWithdrawal(status) {
      if (status === 'rejected') return 'Ditolak'
      if (status === 'requested') return 'Request Penarikan'
      if (status === 'completed') return 'Disetujui'
      if (status === 'on_review') return 'Sedang Direview'
      if (status === 'in_process') return 'Diproses'
      return ''
    },
    statusWithdrawalClass(status) {
      if (status === 'rejected') return 'rejected'
      if (status === 'requested') return 'requested'
      if (status === 'on_review') return 'on_review'
      if (status === 'completed') return 'completed'
      if (status === 'in_process') return 'in_process'
      return ''
    },
    labelTransactionType(description) {
      if (description === 'outbound_fee') return 'Outbound'
      if (description === 'outbound_goods') return 'Penarikan Barang'
      if (description === 'payment_low_moving_stock') return 'Biaya Low Moving Stok'
      return ''
    },
    labelNominal(item) {
      const { type, nominal } = item
      if (type === 'credit') return `+ ${IDR(nominal)}`
      if (type === 'debit') return `- ${IDR(nominal)}`
      return ''
    },
    classNominal(type) {
      if (type === 'credit') return 'typeGreen'
      if (type === 'debit') return 'typeRed'
      return ''
    },
    onRowSelected(data) {
      const { description, order_id } = data
      if (description === 'orderku_ongkir' || description === 'orderku_done' || description === 'orderku_done' || description === 'orderku_retur') {
        const url = this.$router.resolve({
          // eslint-disable-next-line camelcase
          path: `/tiket/detail/order/${order_id}`,
        })
        window.open(url.href, '_blank')
      }
    },
    toastCopyResi(number) {
      navigator.clipboard.writeText(number)
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: `${number} berhasil disalin`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, 1000)
    },
    handleColorBank(number) {
      if (number < 0) { return 'color: #E31A1A' }
      return 'color: #34A770'
    },
  },
}
</script>

<style scoped lang="scss">
@import './Pencairan.scss';
</style>
