import { render, staticRenderFns } from "./Active.vue?vue&type=template&id=3fb48fe8&scoped=true&"
import script from "./Active.vue?vue&type=script&lang=js&"
export * from "./Active.vue?vue&type=script&lang=js&"
import style0 from "./Active.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Active.vue?vue&type=style&index=1&id=3fb48fe8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb48fe8",
  null
  
)

export default component.exports