<template>
  <BModal
    id="modal-approve-request"
    ref="modal-approve-request"
    hide-footer
    size="md"
    hide-header
    centered
    hide-header-close
  >
    <div
      class="p-[32px]"
    >
      <div>
        <div class="text-[#333333] text-center font-bold text-[20px]">
          Want to approve the document?
        </div>
        <div
          class="text-[#626262] text-center leading-[24px] text-[16px] mt-1"
        >
          Ensure that all required documents meet the necessary criteria and are appropriate for the Collaborator’s intended use.
        </div>
      </div>
      <div class="flex gap-[10px] mt-[32px]">
        <BButton
          variant="outline-secondary"
          class="w-full"
          @click="$bvModal.hide('modal-approve-request')"
        >
          Cancel
        </BButton>
        <BButton
          variant="primary"
          class="w-full"
          @click="handleApprove()"
        >
          Approve
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { collaboratorAxiosIns } from '@/libs/axios'

export default {
  props: {
    getSLA: {
      type: Function,
      default: () => {},
    },
    getCA: {
      type: Function,
      default: () => {},
    },
    getUAT: {
      type: Function,
      default: () => {},
    },
    getDetail: {
      type: Function,
      required: true,
    },
    documentType: {
      type: String,
      default: '',
    },
    paramsUat: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userId: null,
    }
  },
  mounted() {
    const { id } = this.$router.history.current.params
    this.userId = id
  },
  methods: {
    handleApprove() {
      if (this.documentType === 'UAT') {
        this.updateUat()
      } else {
        this.approveReq()
      }
    },
    async approveReq() {
      const params = {
        document_type: this.documentType,
        state: 5,
      }
      const url = '/user/api/v1/admin/user-document-state'
      await collaboratorAxiosIns.put(`${url}/${this.userId}`, params)
        .then(async res => {
          this.$toast_success({ message: 'Request Approved' })
          await this.getDetail()

          // eslint-disable-next-line default-case
          switch (this.documentType.toUpperCase()) {
            case 'SLA':
              if (this.getSLA) await this.getSLA()
              break
            case 'CA':
              if (this.getCA) await this.getCA()
              break
            case 'UAT':
              if (this.getUAT) await this.getUAT()
              break
          }

          this.$bvModal.hide('modal-approve-request')
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal Mengambil Data' })
        })
    },
    async updateUat() {
      const params = {
        input: this.paramsUat,
      }
      const url = '/user/api/v1/admin/document-request/user-acceptance-test'
      await collaboratorAxiosIns.put(`${url}/${this.userId}`, params)
        .then(res => {
          this.approveReq()
        })
        .catch(() => {
          this.$toast_error({ message: 'Failed update UAT' })
        })
    },
  },
}
</script>
