<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <div class="flex">
      <img
        src="https://storage.googleapis.com/komerce/assets/svg/back.svg"
        alt="Komerce"
        class="mr-1 cursor-pointer"
        @click="handleRouterBack"
      >
      <strong class="text-black font-bold text-2xl">
        {{ title }}
      </strong>
    </div>
    <BCard class="mt-1">
      <BRow class="justify-between items-center">
        <BCol md="auto" class="mb-2">
          <BRow>
            <BCol
              v-for="(data, idx) in indicator"
              :key="idx"
              md="auto"
              class="flex items-center"
            >
              <img
                :src="data.img"
                alt="Komerce"
                style="margin-right: 5px"
              >
              <span class="text-black">{{ data.name }}</span>
            </BCol>
          </BRow>
        </BCol>
        <BCol md="auto">
          <BRow>
            <BCol md="auto" class="mb-1">
              <BFormInput
                v-model="search"
                size="md"
                class="w-100"
                placeholder="Cari Kabupaten / Kota"
                style="width: 37%; margin-right: 10px"
                @input="searchDistrict"
              />
            </BCol>
            <BCol md="auto" class="mb-1">
              <DateRangePicker
                ref="picker"
                v-model="dateRange"
                :locale-data="locale"
                :ranges="ranges"
                :opens="'left'"
                class="w-100"
              >
                <template v-slot:input="picker">
                  <div class=" d-flex justify-content-between align-items-center w-100">
                    <div class="mr-1">
                      <span
                        v-if="formatDateParams(picker.startDate) === formatDateParams(last150)"
                        style="color: #828282 !important"
                      >150 Hari terakhir</span>
                      <span
                        v-else-if="formatDateParams(picker.startDate) === formatDateParams(last300)"
                        style="color: #828282 !important"
                      >300 Hari Terakhir</span>
                      <span
                        v-else
                        style="color: #828282 !important"
                      >Semua
                      </span>
                    </div>
                    <div>
                      <feather-icon icon="ChevronDownIcon" />
                    </div>
                  </div>
                </template>
              </DateRangePicker>
            </BCol>
          </BRow>
        </BCol>
      </BRow>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          :items="itemRasioRetur"
          :fields="fieldRasioRetur"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          class="mt-1"
        >
          <template #cell(jne)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'JNE'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span :class="handleLabelPercentage(item.status)">
                  {{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})
                </span>
              </div>
            </div>
          </template>
          <template #cell(ideexpress)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'IDEXPRESS'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span :class="handleLabelPercentage(item.status)">
                  {{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})
                </span>
              </div>
            </div>
          </template>
          <template #cell(sicepat)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'SICEPAT'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span :class="handleLabelPercentage(item.status)">
                  {{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})
                </span>
              </div>
            </div>
          </template>
          <template #cell(sap)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'SAP'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span :class="handleLabelPercentage(item.status)">
                  {{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})
                </span>
              </div>
            </div>
          </template>
          <template #cell(ninja)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'NINJA'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span :class="handleLabelPercentage(item.status)">
                  {{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})
                </span>
              </div>
            </div>
          </template>
          <template #cell(j&t)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'J&T'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span :class="handleLabelPercentage(item.status)">
                  {{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})
                </span>
              </div>
            </div>
          </template>
          <template #cell(average)="data">
            {{ data.item.average }} %
          </template>
          <template #cell(status)="data">
            <div
              :class="handleClassStatus(data.item.status)"
              style="border-radius: 20px"
            >
              {{ data.item.status }}
            </div>
          </template>
        </BTable>
      </BOverlay>
    </BCard>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormInput, BOverlay,
} from 'bootstrap-vue'
import IconVeryGood from '@/assets/images/svg/very-good.svg'
import IconGood from '@/assets/images/svg/good.svg'
import IconMedium from '@/assets/images/svg/medium.svg'
import IconBad from '@/assets/images/svg/bad.svg'
import IconVeryBad from '@/assets/images/svg/very-bad.svg'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import {
  komshipDate, last150, last300, today,
} from '@/store/helpers'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { LABELMONTH, LABELDAY } from '@/libs/filterDate'
import { fieldRasioRetur, indicatorRatioRetur } from '@/views/pages/dashboard-admin-1.1/components/config'
import { komshipAxiosIns } from '@/libs/axios'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    DateRangePicker,
    BOverlay,
  },
  data() {
    return {
      title: 'History Rasio Retur Kota & Kabupaten',
      search: '',
      loading: true,
      indicator: indicatorRatioRetur,
      itemRasioRetur: [],
      fieldRasioRetur,
      monthlabel: LABELMONTH,
      dateRange: {
        startDate: komshipDate,
        endDate: today,
      },
      locale: {
        format: 'dd mmm yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      picker: {
        startDate: komshipDate,
        endDate: today,
      },
      ranges: {
        '150 Hari Terakhir': [last150, today],
        '300 Hari Terakhir': [last300, today],
        'Semua ': [komshipDate, today],
      },
      komshipDate,
      last150,
      last300,
    }
  },
  watch: {
    dateRange: {
      handler() {
        this.getAnalyticRetur()
      },
    },
  },
  mounted() {
    this.getAnalyticRetur()
  },
  methods: {
    formatDateParams(value) {
      return moment(value).format('YYYY-MM-DD')
    },
    async getAnalyticRetur() {
      this.loading = true
      const params = {
        start_date: this.formatDateParams(this.dateRange.startDate),
        end_date: this.formatDateParams(this.dateRange.endDate),
        search: this.search,
      }
      const url = '/v1/admin/dashboard/analytic-retur-area'
      await komshipAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.itemRasioRetur = data
          this.loading = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: error.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    handleRouterBack() {
      this.$router.back()
    },
    handleStatusIndicator(value) {
      if (value === 'Sangat Buruk') {
        return IconVeryBad
      }
      if (value === 'Buruk') {
        return IconBad
      }
      if (value === 'Sedang') {
        return IconMedium
      }
      if (value === 'Bagus') {
        return IconGood
      }
      if (value === 'Sangat Bagus') {
        return IconVeryGood
      }
      return IconVeryGood
    },
    handleClassStatus(value) {
      if (value === 'Sangat Bagus') {
        return 'lableStatusVeryGood'
      }
      if (value === 'Bagus') {
        return 'lableStatusGood'
      }
      if (value === 'Sedang') {
        return 'lableStatusMedium'
      }
      if (value === 'Buruk') {
        return 'lableStatusBad'
      }
      if (value === 'Sangat Buruk') {
        return 'lableStatusVeryBad'
      }
      return 'lableStatusVeryGood'
    },
    handleLabelPercentage(value) {
      if (value === 'Sangat Bagus') {
        return 'lablePercentageVeryGood'
      }
      if (value === 'Bagus') {
        return 'lablePercentageGood'
      }
      if (value === 'Sedang') {
        return 'lablePercentageMedium'
      }
      if (value === 'Buruk') {
        return 'lablePercentageBad'
      }
      if (value === 'Sangat Buruk') {
        return 'lablePercentageVeryBad'
      }
      return 'lablePercentageVeryGood'
    },
    searchDistrict: _.debounce(function search() {
      this.getAnalyticRetur()
    }, 1000),
  },
}
</script>
<style lang="scss" scoped src="../components/Ekspedisi.scss" />
