<template>
  <b-card>
    <h2 class="text-2xl text-black font-semibold">
      Report Performance
    </h2>
    <div class="filter border p-[16px] rounded-lg flex flex-col gap-[16px]">
      <div class="flex justify-end gap-[10px]">
        <BDropdown
          class="border"
          variant="none"
          toggle-class="py-0"
          no-caret
          left
        >
          <template #button-content>
            <span class="text-base font-normal flex items-center gap-[10px]">
              Talent Lead <span class="k-arrow-down-1" />
            </span>
          </template>
          <BDropdownForm class="max-h-[300px] overflow-y-auto">
            <BFormCheckbox
              v-model="talentLead"
              :name="`checkbox-all`"
              class="text-6 mb-[10px] last:mb-0"
              :value="''"
              @change="()=> {talentLead = [''], getCard()}"
            >
              Semua
            </BFormCheckbox>
            <BFormCheckbox
              v-for="(status, index) in talentLeadOptions"
              :key="index"
              v-model="talentLead"
              :name="`checkbox-${index}`"
              class="text-6 mb-[10px] last:mb-0"
              :value="status.value"
              @change="()=> {talentLead = talentLead.filter(e => e !== ''), getCard()}"
            >
              {{ status.text }}
            </BFormCheckbox>
          </BDropdownForm>
        </BDropdown>

        <BDropdown
          class="border"
          variant="none"
          toggle-class="py-0"
          no-caret
          left
        >
          <template #button-content>
            <span class="text-base font-normal flex items-center gap-[10px]">
              Week <span class="k-arrow-down-1" />
            </span>
          </template>
          <BDropdownForm class="max-h-[300px] overflow-y-auto">
            <BFormCheckbox
              v-model="week"
              :name="`checkbox-all`"
              class="text-6 mb-[10px] last:mb-0"
              :value="''"
              @change="()=> {week = [''], getCard()}"
            >
              All Week
            </BFormCheckbox>
            <BFormCheckbox
              v-for="(status, index) in weekOptions"
              :key="index"
              v-model="week"
              :name="`checkbox-${index}`"
              class="text-6 mb-[10px] last:mb-0"
              :value="status.value"
              @change="()=> {week = week.filter(e => e !== ''), getCard()}"
            >
              {{ status.text }}
            </BFormCheckbox>
          </BDropdownForm>
        </BDropdown>

        <MonthlyPicker
          v-model="periode"
          :month-labels="monthlabel"
          date-format="MMM YYYY"
          place-holder="Pilih Periode"
          class="month-picker"
          @selected="getCard()"
        />

        <button
          v-b-toggle.collapse-1
          class="border bg-white px-[12px] rounded-lg [&.not-collapsed>*]:rotate-180 overflow-hidden"
        >
          <span class="k-arrow-down-1 text-3xl leading-10 rotate-0 block transition-transform duration-300" />
        </button>
      </div>
      <b-collapse
        id="collapse-1"
        visible
      >
        <div class="flex gap-[12px]">
          <button
            v-for="(filter, index) in filtering"
            :key="index"
            class="filter border rounded-lg p-[12px] w-full"
          >
            <div class="flex items-center gap-[10px]">
              <div class="rounded-full icon text-2xl flex items-center justify-center">
                <span
                  :class="filter.icon"
                  class="font-medium"
                />
              </div>
              <span class="font-[500] text-lg text-[#333] font-[500]"> {{ filter.text }} </span>
            </div>
            <div class="flex text-[24px] text-[#333] mt-[10px] font-semibold p-[10px]">
              {{ filter.data }}
            </div>
          </button>
        </div>
      </b-collapse>
    </div>
    <div class="flex justify-end my-[24px] gap-[12px]">
      <MonthlyPicker
        v-model="periodeTable"
        :month-labels="monthlabel"
        date-format="MMM YYYY"
        place-holder="Pilih Periode"
        class="month-picker"
      />

      <BDropdown
        class="border"
        variant="none"
        toggle-class="py-0"
        no-caret
        left
      >
        <template #button-content>
          <span class="text-base font-normal flex items-center gap-[10px]">
            Talent Lead <span class="k-arrow-down-1" />
          </span>
        </template>
        <BDropdownForm class="max-h-[300px] overflow-y-auto">
          <BFormCheckbox
            v-model="talentLeadTable"
            :name="`checkbox-all`"
            class="text-6 mb-[10px] last:mb-0"
            :value="''"
            @change="talentLeadTable = ['']"
          >
            Semua
          </BFormCheckbox>
          <BFormCheckbox
            v-for="(status, index) in talentLeadOptions"
            :key="index"
            v-model="talentLeadTable"
            :name="`checkbox-${index}`"
            class="text-6 mb-[10px] last:mb-0"
            :value="status.value"
            @change="()=> {talentLeadTable = talentLeadTable.filter(e => e !== '')}"
          >
            {{ status.text }}
          </BFormCheckbox>
        </BDropdownForm>
      </BDropdown>

      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Search for talent, partner name, partner number"
          class="border-0"
        />
      </div>
    </div>
    <Table
      :talent-lead-table="talentLeadTable"
      :periode-table="periodeTable"
      :keyword="keyword"
    />
  </b-card>
</template>

<script>
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import MonthlyPicker from 'vue-monthly-picker'
import moment from 'moment'
import { komtimAxiosIns } from '@/libs/axios'
import Table from './Table.vue'

export default {
  components: {
    MonthlyPicker,
    Table,
  },
  data() {
    return {
      monthlabel: LABELMONTH,
      periode: moment(),
      periodeTable: moment(),
      filtering: [
        {
          text: 'CR < Target',
          icon: 'k-percentage-square',
          data: 0,
          onTarget: false,
        },
        {
          text: 'CR ≥ Target',
          icon: 'k-percentage-square',
          data: 0,
          onTarget: true,
        },
        {
          text: 'Leads < Target',
          icon: 'k-profile-2user',
          data: 0,
          onTarget: false,
        },
        {
          text: 'Leads ≥ Target',
          icon: 'k-profile-2user',
          data: 0,
          onTarget: true,
        },
      ],
      talentLeadOptions: [],
      talentLead: [''],
      talentLeadTable: [''],
      weekOptions: [
        { text: 'Week 1', value: '1' },
        { text: 'Week 2', value: '2' },
        { text: 'Week 3', value: '3' },
        { text: 'Week 4', value: '4' },
        { text: 'Week 5', value: '5' },
      ],
      keyword: '',
      week: [''],
    }
  },
  mounted() {
    this.getListTeamLead()
    this.getCard()
  },
  methods: {
    async getListTeamLead() {
      this.loading = true
      this.offset = 0
      const url = 'v1/invoices/sharing_fee/talent_lead'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.talentLeadOptions = [...this.talentLeadOptions, ...data.map(e => ({
            text: e.name,
            value: e.id,
          }))]
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getCard() {
      const url = `v1/talent_performance/card?date=${YEAR_MONTH(this.periode)}&talent_lead=${this.talentLead.join(',')}&week=${this.week.join(',')}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          const {
            cr_above_target, cr_below_target, leads_above_target, leads_below_target,
          } = data
          // eslint-disable-next-line camelcase
          this.filtering[0].data = cr_below_target
          // eslint-disable-next-line camelcase
          this.filtering[1].data = cr_above_target
          // eslint-disable-next-line camelcase
          this.filtering[2].data = leads_below_target
          // eslint-disable-next-line camelcase
          this.filtering[3].data = leads_above_target
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
  },
}
</script>

<style scoped>
.icon {
  width: 36px;
  height: 36px;
}
button:nth-child(odd) .icon {
  background: #FFEDED;
  color: #E31A1A;
}
button:nth-child(even) .icon {
  background: #DCF3EB;
  color: #34A770;
}
button.filter:nth-child(odd) {
  border-right: 10px solid #E31A1A !important;
}
button.filter:nth-child(even) {
  border-right: 10px solid #34A770 !important;
}
</style>
<style>
.month-picker .input, .month-picker .month-picker-wrapper, .month-picker .month-year-label {
  height: 100% !important;
}
</style>
